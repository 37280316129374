module.exports = [{
      plugin: require('/home/travis/build/dublincss/dublincss.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('/home/travis/build/dublincss/dublincss.github.io/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dublincss-website","short_name":"dublincss","start_url":"/","background_color":"rgb(52, 84, 209)","theme_color":"rgb(52, 84, 209)","display":"minimal-ui","icon":"src/images/dublincss-favicon.png"},
    },{
      plugin: require('/home/travis/build/dublincss/dublincss.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
